<template>
  <div>
    <h3>Products</h3>
    <v-btn
        text
        :to="{ name: 'add-product' }"
        outlined
        class="btn btn-lg btn-add-main"
        style="background: transparent"
    >
      <i class="fas fa-plus-circle"></i> Add new
    </v-btn>

    <v-app>
      <div class="row mt-2">
        <div class="col-xl-12">
          <KTPortlet>
            <template v-slot:body>
              <div class="row" v-if="loading">
                <div class="col-md-12">
                  <v-skeleton-loader
                      class="mx-auto"
                      type="table"
                  ></v-skeleton-loader>
                </div>
              </div>
              <div class="row" v-else>

                <!-- <v-row>
                    <v-col cols="12">
                      <small><strong><i class="text-danger">Note : Product with no default price will not be displayed on website</i></strong></small>
                    </v-col>
                </v-row>-->
                <template>
                  <v-row>
                    <v-col cols="12">
                      <p>
                        <a @click="productFilter={};filteProduct('all')">All({{all}}) </a> |
                        <a @click="productFilter={};filteProduct('active')"
                        >Active({{ active }})</a
                        > |
                        <a @click="productFilter={};filteProduct('trashed')"
                        >Trash({{ trashed }})</a
                        >
                      </p>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                          dense
                          outlined
                          v-model="productFilter.title"
                          label="Search by product name..."
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                          dense
                          outlined
                          v-model="productFilter.price"
                          label="Price"
                          type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                          dense
                          outlined
                          v-model="productFilter.sku"
                          label="SKU"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                          dense
                          outlined
                          v-model="productFilter.status"
                          label="Status"
                          :items="productStatuses"
                          item-text="name"
                          item-value="value"
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-btn dark rounded @click="filteProduct"
                      >Apply filter
                      </v-btn
                      >
                      <v-btn rounded outlined @click="resetFilter" class="ml-2"
                      >Reset
                      </v-btn
                      >
                    </v-col>
                    <v-col sm="9" md="9" lg="9" v-if="selected.length > 0">
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('activate')"
                      >Activate
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          x-small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('de_activate')"
                      >Deactivate
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('make_featured')"
                      >Mark as featured
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('disable_featured')"
                      >Remove from featured
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('enable_sale')"
                      >Allow purchase
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('disable_sale')"
                      >Disable purchase
                      </v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
                <div class="col-md-12" v-if="products.length > 0">

                  <b-table
                      responsive
                      hover
                      :items="products"
                      id="my-table"
                      :busy.sync="isBusy"
                      :fields="fields"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-info my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading products...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(image)="data">
                      <progressive-img
                          :src="
                          data.item.medias.length > 0
                            ? image(data.item.medias[0].image_path)
                            : 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/No_image_available_600_x_450.svg/600px-No_image_available_600_x_450.svg.png'
                        "
                          height="60"
                          width="60"
                          aspect-ratio="1"
                          class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </progressive-img>
                    </template>
                    <template v-slot:cell(id)="data">
                      <v-checkbox
                          v-model="selected"
                          class="mx-2"
                          :value="data.item.id"
                      ></v-checkbox>
                    </template>
                    <template v-slot:cell(title)="data">
                      <router-link
                          v-if="data.item.sku"
                          :to="{
                          name: 'product-detail',
                          params: { productid: data.item.id },
                        }"
                          class="kt-nav__link"
                      >
                        <h5 class="kt-nav__link-text">{{
                            data.item.title
                          }}</h5>
                      </router-link>
                      <span class="text-success" v-if="data.item.is_active">
                        Active
                        <br/>
                      </span>
                      <span class="text-danger" v-else>
                        Inactive
                        <br/>
                      </span>
                      <span
                          v-if="
                          data.item.is_out_of_stock ||
                          (data.item.manage_stock &&
                            data.item.inventory_stock < 1)
                        "
                      >
                        Out of stock
                        <br/>
                      </span>
                      <span
                          v-else-if="
                          !data.item.is_out_of_stock && !data.item.manage_stock
                        "
                      >
                       Available
                        <br/>
                      </span>
                      <span v-if="data.item.is_sale">
                        On sale
                        <br/>
                      </span>
                      <span v-if="data.item.is_featured">Featured</span>

                      <span v-else>N/A</span>
                    </template>
                    <template v-slot:cell(sku)="data">
                      <span v-if="data.item.sku">{{ data.item.sku }}</span>
                      <span v-else>Add SKU</span>
                    </template>
                    <template v-slot:cell(price)="data">
                      <span v-if="data.item.price && data.item.special_price ">

                    {{ data.item.symbol }} {{ data.item.price }}

                      </span>
                      <span
                          v-else-if="data.item.price && !data.item.enable_special_price"
                      >{{ data.item.symbol }} {{ data.item.price }}</span
                      >
                      <span v-else>Add Price</span>
                    </template>
                    <template v-slot:cell(special_price)="data">
                      <span v-if="data.item.special_price"
                      >{{ data.item.symbol }} {{ data.item.special_price }}</span
                      >
                      <span v-else>-</span>
                    </template>

                    <!-- A virtual composite column -->
                    <template v-slot:cell(action)="data">
                      <div
                          class="kt-widget__toolbar"
                          v-if="!data.item.deleted_at"
                      >
                        <a
                            href="#"
                            class="btn btn-secondary btn-sm text-black-50"
                            data-toggle="dropdown"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </a>
                        <div
                            class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
                        >
                          <ul class="kt-nav">
                            <li class="kt-nav__item">
                              <router-link
                                  :to="{
                                  name: 'product-detail',
                                  params: { productid: data.item.id },
                                }"
                                  class="kt-nav__link"
                              >
                                <i class="kt-nav__link-icon flaticon-edit"></i>
                                <span class="kt-nav__link-text">Edit</span>
                              </router-link>
                            </li>
                            <li class="kt-nav__item">
                              <a
                                  href="#"
                                  class="kt-nav__link"
                                  @click="cloneProduct(data.item.id)"
                              >
                                <i class="kt-nav__link-icon fa fa-clone"></i>
                                <span class="kt-nav__link-text"
                                >Clone Product</span
                                >
                              </a>
                            </li>
                            <li class="kt-nav__item">
                              <router-link
                                  class="kt-nav__link"
                                  :to="{
                                  name: 'product-review',
                                  params: { id: data.item.id },
                                }"
                              >
                                <i class="kt-nav__link-icon flaticon-list"></i>
                                <span class="kt-nav__link-text">Review</span>
                              </router-link>
                              <!-- <a href="#" class="kt-nav__link"

                              </a>-->
                            </li>
                            <li class="kt-nav__item">
                              <a
                                  href="#"
                                  class="kt-nav__link"
                                  @click="deleteProduct(data.item.id)"
                              >
                                <i
                                    class="kt-nav__link-icon flaticon-delete kt-font-danger"
                                ></i>
                                <span class="kt-nav__link-text">Delete</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                          class="kt-widget__toolbar"
                          title="Recover"
                          v-else
                          @click="recoverProduct(data.item.id)"
                      >
                        <i class="fa fa-reply"></i>
                      </div>
                    </template>
                  </b-table>
                  <v-row>
                    <v-col sm="9" md="9" lg="9" v-if="selected.length > 0">
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('activate')"
                      >Activate
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          x-small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('de_activate')"
                      >Deactivate
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('make_featured')"
                      >Make as featured
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('disable_featured')"
                      >Remove from featured
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          rounded
                          :loading="loading"
                          @click="performAction('enable_sale')"
                      >Enable purchase
                      </v-btn
                      >
                      <v-btn
                          class="mr-2"
                          small
                          outlined
                          dense
                          :loading="loading"
                          @click="performAction('disable_sale')"
                      >Disable purchase
                      </v-btn
                      >
                    </v-col>
                    <v-col sm="12" md="12" lg="12">
                      <div class="text-right">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            aria-controls="my-table"
                            class="pull-right"
                            @input="getProducts"
                        ></b-pagination>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="col-md-12 text-center" v-else>
                  <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                  <h5 class="mt-10">Looks like you have not added any data here. Trying adding some data.</h5>
                </div>
              </div>
              <!-- <pre>{{selected}}</pre> -->
            </template>
          </KTPortlet>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import {required} from "vuelidate/lib/validators";
import ProductService from "@/services/store/ProductService";

const Product = new ProductService();
export default {
  name: "website-pages",
  components: {
    KTPortlet,
  },
  validations: {
    page: {
      title: {required},
      custom_slug: {required},
    },
  },
  data() {
    return {
      selected: [],
      productFilter: {},
      all: 0,
      active: 0,
      trashed: 0,
      productStatuses: [
        {value: "active", name: "Active"},
        {value: "in-active", name: "In-active"},
        {value: "featured", name: "Featured"},
        {value: "sale", name: "In Sale"},
      ],
      isBusy: false,
      loading: true,
      pageDialog: false,
      sortBy: "title",
      sortDesc: false,
      seoDialog: false,
      rules: [
        (value) =>
            !value ||
            value.size < 2000000 ||
            "Image size should be less than 2 MB!",
      ],
      url: null,
      fields: [
        {key: "id", label: "", sortable: false},
        {key: "image", label: "Image", sortable: false},
        {key: "title", label: "Product", sortable: true},
        {key: "sku", label: "SKU", sortable: true},
        {key: "price", sortable: false},
        {key: "special_price", sortable: false},
        {key: "action", label: "Action", sortable: false},
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 0,
      pageOptions: [5, 10, 15],
      page: {
        title: "",
        custom_slug: "",
      },
      seo: {
        seo_title: "",
        seo_description: "",
      },
      seoSettingPage: null,
      products: [],
    };
  },
  methods: {
    delete(id) {
    },
    image(imagePath) {
      if (imagePath != null) return imagePath.thumb;
    },

    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    getProducts() {
      this.isBusy = true;

      Product.paginate(this.productFilter, this.currentPage)
          .then((res) => {
            this.products = [];
            this.products = res.data.data;

            window.localStorage.setItem(
                "currentPage",
                res.data.meta.current_page
            );

            this.perPage = res.data.meta.per_page;
            this.totalRows = res.data.meta.total;
            this.selected = [];
            this.getProdcutCount();
          })
          .catch((error) => {
            // console.log(error);
          })
          .finally(() => {
            this.loading = false;
            this.isBusy = false;
          });
    },
    getProdcutCount() {
      this.isBusy = true;
      Product.getCounts()
          .then((res) => {
            this.active = res.data.active;
            this.trashed = res.data.trashed;
            this.all = res.data.all;
          })
          .catch((error) => {
            // console.log(error);
          })
          .finally(() => {
            this.loading = false;
            this.isBusy = false;
          });
    },
    cloneProduct(productId) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            Product.cloneProduct(productId)
                .then((res) => {
                  this.$snotify.success("Product cloned successfully");
                  this.getProducts();
                })
                .catch((error) => {
                  // console.log(error);
                });
          }
        },
      });
    },
    deleteProduct(productId) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            Product.delete(productId)
                .then((res) => {
                  this.$snotify.success("Product cloned successfully");
                  this.getProducts();
                })
                .catch((error) => {
                  // console.log(error);
                });
          }
        },
      });
    },
    recoverProduct(id) {
      this.$confirm({
        message: `Recovering Product", "Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            Product.recoverProdcut(id)
                .then((response) => {
                  this.$snotify.success("Product recovered");
                  this.getProducts();
                })
                .catch((error) => {
                })
                .finally(() => (this.isBusy = false));
          }
        },
      });
    },
    resetFilter() {
      this.productFilter = {};
      this.getProducts();
    },
    filteProduct(status = null) {
      if (status.length > 0) {
        this.productFilter.status = status;
      }
      this.getProducts();
    },
    performAction(action) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            this.isBusy = true;
            let data = {action: action, ids: this.selected};
            Product.performAction(data)
                .then((response) => {
                  this.$snotify.success("Action performed successfully");
                  this.getProducts();
                })
                .catch((error) => {
                  // console.log(error);
                })
                .finally(() => {
                  this.isBusy = false;
                  this.loading = false;
                });
          }
        },
      });
    },
    // editPage(id) {
    //   window.location =
    //     process.env.VUE_APP_SERVER_URL +
    //     "pagebuilder/" +
    //     this.siteUrl +
    //     "/website/editor/" +
    //     id +
    //     "/update";
    //   console.log(id);
    // }
  },
  computed: {
    rows() {
      return this.products.length;
    },
    siteUrl() {
      return this.$route.params.domainname;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Manage Products", route: "/websites"},
    ]);
    this.getProducts();
  },
};
</script>
<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 350px;
}
</style>
